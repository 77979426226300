import { Button, Placeholder } from "@aws-amplify/ui-react";
import { FC, useEffect, useState } from "react";

import { IConnection, OAuthProvider } from "@monorepo/core";

import Modal from "../components/core/modal.component";
import Connection from "../components/domain/connection";
import Header from "../components/layout/header.component";
import { useCreateConnection, useDeleteConnection, useInitiateConnection, useListConnections } from "../hooks/connections.hook";
import usePresentToast from "../hooks/present-toast.hook";
import { usePrevious } from "../hooks/previous.hook";
import PlaidLinkModal from "../components/plaid-link/plaid-link-modal.component";

const HomePage: FC = () => {
  const { initiateConnection } = useInitiateConnection();
  const { isLoading: isConnectionBeingCreated } = useCreateConnection();
  const connectionsListHook = useListConnections();
  const { deleteConnection, isLoading: deleteConnectionIsLoading} = useDeleteConnection();
  const { presentError } = usePresentToast();
  const previousIsDeleting = usePrevious(deleteConnectionIsLoading);

  const [showPlaidConsentModal, setShowPlaidConsentModal] = useState(false);
  const [connectionToDelete, setConnectionToDelete] = useState<IConnection>();

  // Once the delete operation is complete, reset connectionToDelete
  useEffect(() => {
    if (connectionToDelete && previousIsDeleting && !deleteConnectionIsLoading) {
      setConnectionToDelete(undefined);
    }
  }, [previousIsDeleting, deleteConnectionIsLoading])

  return (
    <>
      <div>
        <Header />
        <div
          style={{
            display: 'flex',
            paddingLeft: '4em',
            paddingRight: '4em',
            flexDirection: 'column',
          }}
        >
          <div style={{flex: 1}}>
            <h1>Connections</h1>
          </div>
          <div>
            {connectionsListHook.data?.map((connection) => {
              return (
                <Connection
                  key={connection.id}
                  connection={connection}
                  onDeleteClick={(connection) => {
                    setConnectionToDelete(connection);
                  }}
                  onEditClick={async (connection) => {
                    if (connection.type === 'plaid-item') {
                      setShowPlaidConsentModal(true);
                    } else if (connection.type === 'tesla') {
                      const result = await initiateConnection(OAuthProvider.TESLA);
                      if (result.url) {
                        window.location.href = result.url;
                      }
                    }
                  }}
                />
              )
            })}
            {connectionsListHook.isLoading || isConnectionBeingCreated &&
              <div>
                <Placeholder style={{height: '80px'}}/>
              </div>
            }
          </div>
        </div>
      </div>
      <Modal
        active={!!connectionToDelete}
        title={`Are you sure you would like to delete ${connectionToDelete?.name}?`}
        onClose={() => {
          setConnectionToDelete(undefined);
        }}
      >
        <Button
          onClick={() => {
            if (!connectionToDelete?.id) {
              presentError('Failed to delete connection - Connection ID is missing');
            } else {
              deleteConnection(connectionToDelete);
            }
          }}
          isLoading={deleteConnectionIsLoading}
        >
          Delete Connection
        </Button>
      </Modal>
      <PlaidLinkModal
        showConsentModal={showPlaidConsentModal}
        onClose={() => setShowPlaidConsentModal(false)}
      />
    </>
  );
}

export default HomePage;
