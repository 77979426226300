// Default theme: https://ui.docs.amplify.aws/react/theming/default-theme/colors
export const websiteTheme = {
  name: 'website',
  tokens: {
    colors: {
      primary: {
        10: {value: '{colors.neutral.10.value}' },
        20: { value: '{colors.neutral.20.value}' },
        40: { value: '{colors.neutral.40.value}' },
        60: { value: '{colors.neutral.60.value}'},
        80: { value: '{colors.neutral.80.value}' },
        90: { value: '{colors.neutral.90.value}'},
        100: { value: '{colors.neutral.100.value}' },
      },
      secondary: {
        10: { value: '{colors.blue.10.value}' },
        20: { value: '{colors.blue.20.value}' },
        40: { value: '{colors.blue.40.value}' },
        60: { value: '{colors.blue.60.value}' },
        80: { value: '{colors.blue.80.value}' },
        90: { value: '{colors.blue.90.value}' },
        100: { value: '{colors.blue.100.value}' },
      },
    },
  },
};