import { Authenticator, ThemeProvider } from "@aws-amplify/ui-react";
import { Amplify } from 'aws-amplify';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import '@aws-amplify/ui-react/styles.css';
import "@fontsource/inter";

import { AppPaths } from "@monorepo/core";

import { AuthProvider } from "../contexts/auth.context";
import { TrpcClientProvider } from "../contexts/trpc-client.context";
import ErrorBoundary from "./error-boundary.component";
import HomePage from "../pages/home.page";
import LoginPage from "../pages/login.page";
import LogoutPage from "../pages/logout.page";
import NoInternetPage from "../pages/no-internet.page";
import { RequireAuth } from "./require-auth.component";
import { websiteTheme } from "../theme";

if (!process.env.REACT_APP_USER_POOL_ID || ! process.env.REACT_APP_USER_POOL_CLIENT_ID) {
  throw new Error('Missing AWS config data');
}

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    }
  },
});

function App() {
  return (
    <ThemeProvider theme={websiteTheme}>
      <ErrorBoundary>
        <Authenticator.Provider>
          <BrowserRouter>
            <AuthProvider>
              <TrpcClientProvider>
                <Routes>
                  <Route path={AppPaths.LOGIN} element={<LoginPage />} />
                  <Route path={AppPaths.LOGOUT} element={<LogoutPage />} />
                  <Route path={AppPaths.NO_INTERNET} element={<NoInternetPage/>}/>
                  <Route path={AppPaths.HOME} element={<RequireAuth><HomePage /></RequireAuth>} />
                </Routes>
                <ToastContainer
                  position="bottom-right"
                />
              </TrpcClientProvider>
            </AuthProvider>
          </BrowserRouter>
        </Authenticator.Provider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
