import { z } from 'zod';

import { TeslaInitiateConnectionResultSchema } from './tesla.js';
import { OAuthProvider } from '../types/enums.js';

export const ConnectionTypeSchema = z.union([z.nativeEnum(OAuthProvider), z.literal('plaid-item')]);

export const ConnectionTokenSchema = z.object({ token: z.string() });

export const ConnectionCreateSchema = z.object({ type: ConnectionTypeSchema }).merge(ConnectionTokenSchema.partial());

export const ConnectionInitiateSchema = z.object({
  type: ConnectionTypeSchema,
  id: z.string().optional(), // Use this to update an existing connection (currently only for Plaid)
});

export const ConnectionInitiateResultSchema = ConnectionTokenSchema.merge(
  TeslaInitiateConnectionResultSchema,
).partial();

export const ConnectionSchema = z.object({
  name: z.string(),
  image: z.string(),
  type: ConnectionTypeSchema,
  id: z.string().optional(),
  units: z.array(z.string()).optional(),
  warnings: z.array(z.string()).optional(),
  status: z.record(z.string(), z.string().optional()).optional(),
});
