export enum Account {
  JOINT_CHECKING = 'Joint Checking',
  BRIAN_CHECKING = "Brian's Checking",
  BRENNA_CHECKING = "Brenna's Checking",
  DIGITAL_DAWN_CHECKING = 'Digital Dawn Checking',
  JOINT_PERSONAL_CAPITAL_SAVINGS = 'Joint Personal Capital Savings',
  BRIAN_ETRADE = 'ETrade (Brian)',
  CHILDREN_ETRADE = 'Children Fund (ETrade)',
  BRIAN_IRA_ETRADE = "Brian's IRA (ETrade)",
  BRIAN_ROTH_IRA_ETRADE = "Brian's Roth IRA (ETrade)",
  BRENNA_401K = "Brenna's 401k",
  COINBASE = 'Coinbase',
  BRENNA_HSA = "Brenna's HSA",
  BRENNA_FSA = "Brenna's FSA",
}

export enum AssetEstimate {
  ZILLOW_HOME = 'Zillow Estimate',
  TESLA_KBB = 'Tesla KBB',
  EQUINOX_KBB = 'Equinox KBB',
}

export enum Award {
  CHASE_RESERVE_POINTS = 'Chase Reserve Points',
  CHASE_RESERVE_REMAINING_TRAVEL_CREDIT = 'Remaining Travel Credit',
}

export enum Calculations {
  MONTH_PERCENT_CHANGE = 'Percent Change from Last Month',
  YEAR_PERCENT_CHANGE = 'Percent Change from Last Year',
}

export enum Debt {
  JOINT_CREDIT_CARD = 'Joint Credit Card',
  BRIAN_CREDIT_CARD = "Brian's Credit Card",
  BRENNA_CREDIT_CARD = "Brenna's Credit Card",
  MORTGAGE = 'Remaining Mortgage',
}

export enum Section {
  BILLS = 'Bills',
  SUBSCRIPTIONS = 'Subscriptions',
  SPENDING = 'Spending',
  INCOME = 'Income',
  CASHFLOW = 'Cashflow',
  CREDIT_CARD_BENEFITS = 'Credit Card Benefits',
  SNAPSHOT = 'Snapshot',
}

export enum VehicleMileage {
  TESLA = 'Tesla Mileage',
  EQUINOX = 'Equinox Mileage',
}

export enum Calculations {
  ANNUAL_CASHFLOW = 'Annual Cashflow',
  ANNUAL_SPENDABLE_CASHFLOW = 'Annual Spendable Cashflow',
  BILLS_TOTAL = 'Bills Total',
  CHECKING_SPENDING_TOTAL = 'Checking Spending Total',
  CHECKING_SPENDING_YTD = 'Checking Spending YTD',
  CHECKING_TOTAL = 'Checking Total',
  CHILDREN_INVESTMENT_YTD = "Children's Investment YTD",
  CREDIT_SPENDING_TOTAL = 'Credit Spending Total',
  CREDIT_SPENDING_YTD = 'Credit Spending YTD',
  HOUSE_EQUITY = 'House Equity',
  INCOME_TOTAL = 'Income Total',
  INVESTMENT_TOTAL = 'Investment Total',
  INVESTMENTS_TOTAL = 'Investments Total',
  INVESTMENT_YTD = 'Investment YTD',
  NET_SPENDABLE = 'Net Spendable',
  NET_TOTAL = 'Net Total',
  NETWORTH = 'Networth',
  RECURRING_COST_TOTAL = 'Recurring Cost Total',
  SA_CONTRIBUTIONS_TOTAL = 'SA Contributions Total',
  SA_CONTRIBUTIONS_YTD = 'SA Contributions YTD',
  SA_TOTAL = 'SA Total',
  SAVINGS_TOTAL = 'Savings Total',
  SPENDABLE_INCOME = 'Spendable Income',
  SPENDABLE_INCOME_TOTAL = 'Spendable Income Total',
  SPENDABLE_INCOME_YTD = 'Spendable Income YTD',
  SUBSCRIPTIONS_TOTAL = 'Subscriptions Total',
  TOTAL_SPENDING = 'Total Spending',
  TOTAL_SPENDING_YTD = 'Total Spending YTD',
}
