import { Loader } from "@aws-amplify/ui-react";
import { capitalize } from "lodash";
import { FC, useState } from "react";

import { IConnection } from "@monorepo/core";

import { useDeleteConnection } from "../../hooks/connections.hook";

interface IProps {
  connection: IConnection;
  onEditClick: (connection: IConnection) => void;
  onDeleteClick: (connection: IConnection) => void;
}

const Connection: FC<IProps>  = ({ connection, onEditClick, onDeleteClick }) => {
  const deleteConnectionHook = useDeleteConnection();

  const [isEditLoading, setEditIsLoading] = useState(false);

  return (
    <div key={connection.name} style={{display: 'flex', padding: '1em', borderBottomWidth: '1px', borderBottomColor: '#e6e6e6', borderBottomStyle:'solid'}}>
      <div
        style={{
          width: '75px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <img src={connection.image} style={{height: '50px'}}/>
      </div>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-start', paddingLeft: '1em', flexDirection: 'column'}}>
        <div style={{fontSize: '12px', color: (!connection.units && connection.warnings) ? 'orange' : undefined}}>{(connection.units ? connection.units : connection.warnings ?? []).join(', ')}</div>
        {Object.entries(connection.status ?? {}).map(([key, value]) => (
          <div key={key} style={{fontSize: '12px'}}>{capitalize(key)}: {capitalize(value ?? 'Unknown')}</div>
        ))}
      </div>
      <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'center'}}>
        <div
          style={{cursor: 'pointer', marginLeft: '.75em'}}
          onClick={async () => {
            setEditIsLoading(true);
            await onEditClick(connection);
            setEditIsLoading(false);
          }}
        >
          {isEditLoading
            ? <Loader/>
            : <img src={`${process.env.REACT_APP_CDN_URL}/icons/edit.svg`} style={{height: '15px'}}/>
          }
        </div>
        <div
          style={{cursor: 'pointer', marginLeft: '.75em'}}
          onClick={() => {
            onDeleteClick(connection);
          }}
        >
          {deleteConnectionHook.isLoading
            ? <Loader/>
            : <img src={`${process.env.REACT_APP_CDN_URL}/icons/delete.svg`} style={{height: '15px'}}/>
          }
        </div>
      </div>
    </div>
  )
};

export default Connection;
