import { type TRPCClientErrorLike } from '@trpc/client';
import { type UseTRPCQueryResult } from '@trpc/react-query/shared';
import { useState } from 'react';

import { type Connection, type IConnection } from '@monorepo/core';
import type { AppRouter } from '@monorepo/lambda-trpc';

import usePresentToast from './present-toast.hook';
import { type ReactQueryOptions, trpc } from '../utils/trpc';

// TODO: This logic will only handle creating one connection at a time
// TODO: There should be way to useCreateConnection(key) that returns hook for that key, so we know that it's loading for that key
export function useCreateConnection() {
  const utils = trpc.useUtils();
  const createConnectionMutation = trpc.connections.create.useMutation();
  const { presentError } = usePresentToast();

  const [isLoading, setIsLoading] = useState(false);

  const createConnection = async (type: Connection, token?: string) => {
    try {
      setIsLoading(true);
      return await createConnectionMutation.mutateAsync(
        {
          type,
          token,
        },
        {
          onSuccess: () => {
            utils.connections.invalidate();
          },
        },
      );
    } catch (e) {
      presentError('Failed to create connection');
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    createConnection,
  };
}

// TODO: This logic will only handle initiating one connection at a time
// TODO: There should be way to useInitiateConnection(key) that returns hook for that key, so we know that it's loading for that key
export function useInitiateConnection() {
  const createConnectionMutation = trpc.connections.initiate.useMutation();
  const { presentError } = usePresentToast();

  const [isLoading, setIsLoading] = useState(false);

  const initiateConnection = async (type: Connection, id?: string) => {
    try {
      setIsLoading(true);
      return await createConnectionMutation.mutateAsync({
        type,
        id,
      });
    } catch (e) {
      presentError('Failed to initiate connection');
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    initiateConnection,
    isLoading,
  };
}

export function useListConnections(
  options?: ReactQueryOptions['connections']['list'],
): UseTRPCQueryResult<IConnection[], TRPCClientErrorLike<AppRouter>> {
  return trpc.connections.list.useQuery(undefined, {
    ...options,
  });
}

// TODO: This logic will only handle deleting one connection at a time
// TODO: There should be way to useDeleteConnection(key) that returns hook for that key, so we know that it's loading for that key
export function useDeleteConnection() {
  const utils = trpc.useUtils();
  const deleteConnectionMutation = trpc.connections.delete.useMutation();
  const { presentError } = usePresentToast();

  const [isLoading, setIsLoading] = useState(false);

  const deleteConnection = async (connection: Pick<IConnection, 'type'> & Pick<Partial<IConnection>, 'id'>) => {
    try {
      setIsLoading(true);
      return await deleteConnectionMutation.mutateAsync(connection, {
        onSuccess: () => {
          utils.connections.invalidate();
        },
      });
    } catch (e) {
      presentError('Failed to delete Connection');
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    deleteConnection,
  };
}
