export enum ETradeAccountMode {
  CASH = 'CASH',
  MARGIN = 'MARGIN',
  CHECKING = 'CHECKING',
  IRA = 'IRA',
  SAVINGS = 'SAVINGS',
  CD = 'CD',
}

export enum ETradeAccountStatus {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
}

export enum ETradeAccountType {
  // cspell:disable
  AMMCHK = 'AMMCHK',
  ARO = 'ARO',
  BCHK = 'BCHK',
  BENFIRA = 'BENFIRA',
  BENFROTHIRA = 'BENFROTHIRA',
  BENF_ESTATE_IRA = 'BENF_ESTATE_IRA',
  BENF_MINOR_IRA = 'BENF_MINOR_IRA',
  BENF_ROTH_ESTATE_IRA = 'BENF_ROTH_ESTATE_IRA',
  BENF_ROTH_MINOR_IRA = 'BENF_ROTH_MINOR_IRA',
  BENF_ROTH_TRUST_IRA = 'BENF_ROTH_TRUST_IRA',
  BENF_TRUST_IRA = 'BENF_TRUST_IRA',
  BRKCD = 'BRKCD',
  BROKER = 'BROKER',
  CASH = 'CASH',
  C_CORP = 'C_CORP',
  CONTRIBUTORY = 'CONTRIBUTORY',
  COVERDELL_ESA = 'COVERDELL_ESA',
  CONVERSION_ROTH_IRA = 'CONVERSION_ROTH_IRA',
  CREDITCARD = 'CREDITCARD',
  COMM_PROP = 'COMM_PROP',
  CONSERVATOR = 'CONSERVATOR',
  CORPORATION = 'CORPORATION',
  CSA = 'CSA',
  CUSTODIAL = 'CUSTODIAL',
  DVP = 'DVP',
  ESTATE = 'ESTATE',
  EMPCHK = 'EMPCHK',
  EMPMMCA = 'EMPMMCA',
  ETCHK = 'ETCHK',
  ETMMCHK = 'ETMMCHK',
  HEIL = 'HEIL',
  HELOC = 'HELOC',
  INDCHK = 'INDCHK',
  INDIVIDUAL = 'INDIVIDUAL',
  INDIVIDUAL_K = 'INDIVIDUAL_K',
  INVCLUB = 'INVCLUB',
  INVCLUB_C_CORP = 'INVCLUB_C_CORP',
  INVCLUB_LLC_C_CORP = 'INVCLUB_LLC_C_CORP',
  INVCLUB_LLC_PARTNERSHIP = 'INVCLUB_LLC_PARTNERSHIP',
  INVCLUB_LLC_S_CORP = 'INVCLUB_LLC_S_CORP',
  INVCLUB_PARTNERSHIP = 'INVCLUB_PARTNERSHIP',
  INVCLUB_S_CORP = 'INVCLUB_S_CORP',
  INVCLUB_TRUST = 'INVCLUB_TRUST',
  IRA_ROLLOVER = 'IRA_ROLLOVER',
  JOINT = 'JOINT',
  JTTEN = 'JTTEN',
  JTWROS = 'JTWROS',
  LLC_C_CORP = 'LLC_C_CORP',
  LLC_PARTNERSHIP = 'LLC_PARTNERSHIP',
  LLC_S_CORP = 'LLC_S_CORP',
  LLP = 'LLP',
  LLP_C_CORP = 'LLP_C_CORP',
  LLP_S_CORP = 'LLP_S_CORP',
  IRA = 'IRA',
  IRACD = 'IRACD',
  MONEY_PURCHASE = 'MONEY_PURCHASE',
  MARGIN = 'MARGIN',
  MRCHK = 'MRCHK',
  MUTUAL_FUND = 'MUTUAL_FUND',
  NONCUSTODIAL = 'NONCUSTODIAL',
  NON_PROFIT = 'NON_PROFIT',
  OTHER = 'OTHER',
  PARTNER = 'PARTNER',
  PARTNERSHIP = 'PARTNERSHIP',
  PARTNERSHIP_C_CORP = 'PARTNERSHIP_C_CORP',
  PARTNERSHIP_S_CORP = 'PARTNERSHIP_S_CORP',
  PDT_ACCOUNT = 'PDT_ACCOUNT',
  PM_ACCOUNT = 'PM_ACCOUNT',
  PREFCD = 'PREFCD',
  PREFIRACD = 'PREFIRACD',
  PROFIT_SHARING = 'PROFIT_SHARING',
  PROPRIETARY = 'PROPRIETARY',
  REGCD = 'REGCD',
  ROTHIRA = 'ROTHIRA',
  ROTH_INDIVIDUAL_K = 'ROTH_INDIVIDUAL_K',
  ROTH_IRA_MINORS = 'ROTH_IRA_MINORS',
  SARSEPIRA = 'SARSEPIRA',
  S_CORP = 'S_CORP',
  SEPIRA = 'SEPIRA',
  SIMPLE_IRA = 'SIMPLE_IRA',
  TIC = 'TIC',
  TRD_IRA_MINORS = 'TRD_IRA_MINORS',
  TRUST = 'TRUST',
  VARCD = 'VARCD',
  VARIRACD = 'VARIRACD',
  // cspell:enable
}
export enum ETradeInstitutionType {
  // cspell:disable
  BROKERAGE = 'BROKERAGE',
  GLOBALTRADING = 'GLOBALTRADING',
  NONUS = 'NONUS',
  STOCKPLAN = 'STOCKPLAN',
  LENDING = 'LENDING',
  HELOC = 'HELOC',
  HEIL = 'HEIL',
  ONTRACK = 'ONTRACK',
  GENPACT = 'GENPACT',
  AUTO = 'AUTO',
  AUTOLOAN = 'AUTOLOAN',
  BETA = 'BETA',
  LOYALTY = 'LOYALTY',
  SBASKET = 'SBASKET',
  CC_BALANCETRANSFER = 'CC_BALANCETRANSFER',
  GENPACT_LEAD = 'GENPACT_LEAD',
  GANIS = 'GANIS',
  MORTGAGE = 'MORTGAGE',
  EXTERNAL = 'EXTERNAL',
  FUTURES = 'FUTURES',
  VISA = 'VISA',
  RJO = 'RJO',
  WDBH = 'WDBH',
  // cspell:enable
}

export enum ETradeOptionLevel {
  NO_OPTIONS = 'NO_OPTIONS',
  LEVEL_1 = 'LEVEL_1',
  LEVEL_2 = 'LEVEL_2',
  LEVEL_3 = 'LEVEL_3',
  LEVEL_4 = 'LEVEL_4',
}

export enum ETradeQuoteMode {
  REALTIME = 0,
  DELAYED = 1,
  CLOSING = 2,
  AHT_REALTIME = 3,
  AHT_BEFORE_OPEN = 4,
  AHT_CLOSING = 5,
  NONE = 6,
}

export enum ETradeSecurityType {
  // cspell:disable
  BOND = 'BOND',
  EQ = 'EQ',
  INDX = 'INDX',
  MF = 'MF',
  MMF = 'MMF',
  OPTN = 'OPTN',
  // cspell:enable
}

export enum ETradeOptionType {
  CALL = 'CALL',
  PUT = 'PUT',
}

export enum ETradeProductType {
  // cspell:disable
  EQUITY = 'EQUITY',
  OPTION = 'OPTION',
  MUTUAL_FUND = 'MUTUAL_FUND',
  INDEX = 'INDEX',
  MONEY_MARKET_FUND = 'MONEY_MARKET_FUND',
  BOND = 'BOND',
  UNKNOWN = 'UNKNOWN',
  WILDCARD = 'WILDCARD',
  MOVE = 'MOVE',
  ETF = 'ETF',
  EQUITY_OPTION_ETF = 'EQUITY_OPTION_ETF',
  EQUITY_ETF = 'EQUITY_ETF',
  CLOSED_END_FUND = 'CLOSED_END_FUND',
  PREFERRED = 'PREFERRED',
  EQUITY_OPTN = 'EQUITY_OPTN',
  EXCHANGE_TRADED_FUND = 'EXCHANGE_TRADED_FUND',
  MUTUAL_FUND_MONEY_MARKET_FUND = 'MUTUAL_FUND_MONEY_MARKET_FUND',
  // cspell:enable
}

// NOTE: This is not exhaustive
export enum ETradeTransactionType {
  DEPOSIT = 'Deposit',
  BOUGHT = 'Bought',
  SOLD = 'Sold',
  TRANSFER = 'Transfer',
  INTEREST = 'Interest',
  DIVIDEND = 'Dividend',
}
