import { FC, useEffect } from "react";

import { Authenticator, View } from '@aws-amplify/ui-react';
import { useLocation, useNavigate } from "react-router-dom";

import useAuth from "../hooks/auth.hook";

const authtenticatorFormFields = {
  signIn: {
    username: {
      placeholder: 'Email',
    },
  },
  signUp: {
    given_name: {
      placeholder: 'First Name',
      order: 1,
    },
    family_name: {
      placeholder: 'Last Name',
      order: 2,
    },
    username: {
      placeholder: 'Email',
      order: 3
    },
  },
  resetPassword: {
    username: {
      placeholder: 'Email',
    },
  },
};

const Login: FC = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const from = (location.state as any)?.from?.pathname || '/';

  useEffect(() => {
    if (isAuthenticated) {
      navigate(from, { replace: true });
    }
  }, [isAuthenticated, navigate, from]);

  return (
    <div 
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <style>{`
        [data-amplify-authenticator] {
          --amplify-components-button-primary-hover-color: black;
          --amplify-components-button-primary-active-color: black;

          --amplify-components-button-primary-background-color: black;
          --amplify-components-button-primary-hover-background-color: white;
          --amplify-components-button-primary-active-background-color: white;

          --amplify-components-button-primary-hover-border-color: black;

          --amplify-components-button-link-color: gray;
          --amplify-components-button-link-hover-color: lightgray;
          --amplify-components-button-link-active-color: lightgray;

          --amplify-components-button-link-hover-background-color: white;
          --amplify-components-button-link-active-background-color: white;
          --amplify-components-button-link-focus-background-color: white;
        }
      `}</style>
       <h1>My Cash Flow Report</h1>
       <View
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
        <Authenticator 
          formFields={authtenticatorFormFields}
          signUpAttributes={['given_name', 'family_name']}
          // NOTE: Enable signup to create users with MFA OTP
          hideSignUp={true}
        />
      </View>
    </div>
   
  );
};

export default Login;