// https://gist.github.com/mikelehen/3596a30bd69384624c11
/**
 * Fancy ID generator that creates 20-character string identifiers with the following properties:
 *
 * 1. They're based on timestamp so that they sort *after* any existing ids.
 * 2. They contain 72-bits of random data after the timestamp so that IDs won't collide with other clients' IDs.
 * 3. They sort *lexicographically* (so the timestamp is converted to characters that will sort properly).
 * 4. They're monotonically increasing.  Even if you generate more than one in the same timestamp, the
 *    latter ones will sort after the former ones.  We do this by using the previous random bits
 *    but "incrementing" them by 1 (only in the case of a timestamp collision).
 */

// Modeled after base64 web-safe chars, but ordered by ASCII.
const PUSH_CHARS = '-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz'; // cspell:disable-line

// Timestamp of last push, used to prevent local collisions if you push twice in one ms.
let LastPushTime = 0;

// We generate 72-bits of randomness which get turned into 12 characters and appended to the
// timestamp to prevent collisions with other clients.  We store the last characters we
// generated because in the event of a collision, we'll use those same characters except
// "incremented" by one.
const LastRandChars = new Array(12).fill(0);

const FirstID = PUSH_CHARS[0].repeat(20);
const LastID = PUSH_CHARS.slice(-1)[0].repeat(20);

export const useFirebasePushID = () => {
  /**
   * Generates chronologically orderable unique string one by one
   */
  function generate(timestamp?: number): string {
    const ts = timestamp ?? Date.now();
    const duplicateTime = ts === LastPushTime;
    LastPushTime = ts;
    const idObj = getIDFromTimestamp(ts);

    let i: number;
    let id = idObj;

    if (!duplicateTime) {
      for (i = 0; i < 12; i++) {
        LastRandChars[i] = Math.floor(Math.random() * 64);
      }
    } else {
      // If the timestamp hasn't changed since last push, use the same random number, except incremented by 1.
      for (i = 11; i >= 0 && LastRandChars[i] === 63; i--) {
        LastRandChars[i] = 0;
      }
      LastRandChars[i]++;
    }
    for (i = 0; i < 12; i++) {
      id += PUSH_CHARS.charAt(LastRandChars[i]);
    }
    if (id.length != 20) throw new Error('Length should be 20.');

    return id;
  }

  /**
   * Generates the first ID for the given timestamp
   */
  function startID(timestamp?: number): string {
    const now = timestamp ?? Date.now();
    let id = getIDFromTimestamp(now);
    id += PUSH_CHARS[0].repeat(12);

    return id;
  }

  /**
   * Generates the last ID for the given timestamp
   */
  function endID(timestamp?: number): string {
    const now = timestamp ?? Date.now();
    let id = getIDFromTimestamp(now);
    id += PUSH_CHARS.slice(-1)[0].repeat(12);

    return id;
  }

  /**
   * Get the last ID, one millisecond before this one
   */
  function previousMillisecondID(pushID: string): string {
    validatePushID(pushID);
    const ts = getTimestamp(pushID);

    // There could be more error checking on the timestamp range, but if we are at the beginning or end of millisecond time and using this incorrectly, we have bigger problems
    return endID(ts - 1);
  }

  /**
   * Get the next ID, one millisecond after this one
   */
  function nextMillisecondID(pushID: string): string {
    validatePushID(pushID);
    const ts = getTimestamp(pushID);

    // There could be more error checking on the timestamp range, but if we are at the beginning or end of millisecond time and using this incorrectly, we have bigger problems
    return startID(ts + 1);
  }

  /**
   * Get the previous ID
   */
  function previousID(pushID: string): string {
    validatePushID(pushID);
    if (pushID === FirstID) {
      throw new Error('ID is the beginning of time! There are no previous push IDs!');
    }

    // Sigh... A string is a primitive value, it's immutable.
    const pushIdChars = [...pushID];

    let index = pushIdChars.length - 1;
    // If we have a '-', we need to turn it to a 'z' and roll back the previous character
    while (pushIdChars[index] === PUSH_CHARS[0]) {
      pushIdChars[index] = PUSH_CHARS[PUSH_CHARS.length - 1];
      index--;
    }

    pushIdChars[index] = PUSH_CHARS[PUSH_CHARS.indexOf(pushIdChars[index]) - 1];

    return pushIdChars.join('');
  }

  /**
   * Get the next ID
   */
  function nextID(pushID: string): string {
    validatePushID(pushID);
    if (pushID === LastID) {
      throw new Error('ID is the end of time! There are no next push IDs!');
    }

    // Sigh... A string is a primitive value, it's immutable.
    const pushIdChars = [...pushID];

    let index = pushIdChars.length - 1;
    // If we have a 'z', we need to turn it to a '-' and roll back the previous character
    while (pushIdChars[index] === PUSH_CHARS[PUSH_CHARS.length - 1]) {
      pushIdChars[index] = PUSH_CHARS[0];
      index--;
    }

    pushIdChars[index] = PUSH_CHARS[PUSH_CHARS.indexOf(pushIdChars[index]) + 1];

    return pushIdChars.join('');
  }

  /**
   * Validates if the provided pushID is a valid Firebase Push ID
   */
  function isValid(pushID: string): boolean {
    const matchArray: RegExpMatchArray | null = pushID.match(
      /^[-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz]{20}$/, // cspell:disable-line
    );

    return matchArray !== null;
  }

  /**
   * Checks if the provided pushId is an end ID
   */
  function isEndId(pushId: string): boolean {
    const randomBitsIdentifier = PUSH_CHARS.slice(-1)[0].repeat(12);
    return pushId.endsWith(randomBitsIdentifier);
  }

  /**
   * Gets timestamp (in milliseconds since epoch) from provided uid.
   */
  function getTimestamp(pushID: string): number {
    validatePushID(pushID);
    let time = 0;
    const data = pushID.substr(0, 8);

    for (let i = 0; i < 8; i++) {
      time = time * 64 + PUSH_CHARS.indexOf(data[i]);
    }

    return time;
  }

  /**
   * Calculates difference in days between two push IDs
   */
  function getDiffDays(startId: string, endId: string) {
    const startDate = new Date(getTimestamp(startId));
    const endDate = new Date(getTimestamp(endId));
    const diffTime = endDate.getTime() - startDate.getTime();
    const diffDays = diffTime / (1000 * 60 * 60 * 24); // Convert from milliseconds to days
    return diffDays > 0 ? Math.round(diffDays) : 0;
  }

  // Private helpers

  /**
   * Converts a timestamp to a push ID
   */
  function getIDFromTimestamp(now: number) {
    const timeStampChars = new Array(8);
    let i: number;
    for (i = 7; i >= 0; i--) {
      timeStampChars[i] = PUSH_CHARS.charAt(now % 64);
      now = Math.floor(now / 64);
    }
    if (now !== 0) throw new Error('We should have converted the entire timestamp. Is this a valid Push ID?');

    return timeStampChars.join('');
  }

  /**
   * Validates if the provided pushID is valid
   */
  function validatePushID(pushID: string) {
    if (!isValid(pushID)) {
      throw new Error(`Firebase Push ID ${pushID} is Invalid!`);
    }
  }

  return {
    FirstID,
    LastID,
    generate,
    startID,
    endID,
    previousMillisecondID,
    nextMillisecondID,
    previousID,
    nextID,
    isValid,
    isEndId,
    getTimestamp,
    getDiffDays,
  };
};
