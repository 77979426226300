import { FC, useCallback, useEffect, useState } from "react";
import { usePlaidLink } from "react-plaid-link";

export const PlaidLink: FC<{
  linkToken?: string,
  onSuccess: (publicToken: string) => Promise<void>;
}> = ({
  linkToken,
  onSuccess,
}) => {
  const [redirectUri, setRedirectUri] = useState<string>();

  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken || "",
    receivedRedirectUri: redirectUri,
    onSuccess: useCallback(onSuccess, []),
  };
  const { open: openPlaidLink, ready: isPlaidLinkReady } = usePlaidLink(config);

  useEffect(() => {
    const queryParms = new URLSearchParams(window.location.search);
    if (queryParms.has('oauth_state_id')) {
      setRedirectUri(window.location.href);
    }
  }, []);

  useEffect(() => {
    if (isPlaidLinkReady && (redirectUri || linkToken)) {
      openPlaidLink();
    }
  }, [isPlaidLinkReady, openPlaidLink, redirectUri, linkToken]);

  return <></>;
}