import { Menu, MenuItem } from "@aws-amplify/ui-react";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AppPaths, OAuthProvider } from "@monorepo/core";

import useAuth from "../../hooks/auth.hook";
import PlaidLinkModal from "../plaid-link/plaid-link-modal.component";
import { useInitiateConnection } from "../../hooks/connections.hook";

const Header: FC = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { initiateConnection } = useInitiateConnection();

  const [showConsentModal, setShowConsentModal] = useState(false);

  return (
    <div 
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '1.5em',
      }}
    >
      <div
        style={{
          paddingLeft: '1em',
          paddingRight: '3em',
        }}
        >
        <div
          onClick={() => navigate(AppPaths.HOME)}
          style={{fontWeight: 'bold', cursor: 'pointer'}}
        >
          My Cashflow Report
        </div>
      </div>
      <div style={{display: 'flex', flexGrow: 1}}>
        <Menu
          menuAlign="start"
          trigger={
            <p style={{cursor: 'pointer'}}>
              Create Connection
            </p>
          }
        >
          <MenuItem onClick={() => setShowConsentModal(true)}>
            Plaid
          </MenuItem>
          <MenuItem
            onClick={async () => {
              const result = await initiateConnection(OAuthProvider.TESLA)
              if (result.url) {
                window.location.href = result.url;
              }
            }}
          >
            Tesla
          </MenuItem>
        </Menu>
      </div>
      <div
        style={{
          paddingLeft: '1em',
          paddingRight: '1em',
          cursor: 'pointer'
        }}
      >
        <div
          onClick={logout}
        >
          Logout
        </div>
      </div>
      <PlaidLinkModal
        showConsentModal={showConsentModal}
        onClose={() => setShowConsentModal(false)}
      />
    </div>
  );
};

export default Header;