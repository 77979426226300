export enum AppPaths {
  HOME = '/',
  LOGIN = '/login',
  LOGOUT = '/logout',
  NO_INTERNET = '/no-internet',
  PLAID_ITEMS_CREATE = '/plaid-items/create',
}

export enum AppRole {
  Member = 'Member',
  Admin = 'Admin',
}

export enum OAuthProvider {
  TESLA = 'tesla',
}

// NOTE: Institution IDs are consistent across environments
export enum PlaidItemInstitution {
  CHASE = 'ins_56',
  WELLS_FARGO = 'ins_127991',
}

export enum PlaidItemStatus {
  HEALTHY = 'HEALTHY',
  UNHEALTHY = 'UNHEALTHY',
  UNKNOWN = 'UNKNOWN',
}
