import { Flex, Heading } from "@aws-amplify/ui-react";
import { FC, ReactNode } from "react";

interface ContainerProps {
  active: boolean;
  title: string;
  children: ReactNode;
  onClose: () => void;
}
const Modal: FC<ContainerProps> = ({
  active,
  title,
  children,
  onClose,
}) => {
  return active ? (
    <Flex
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      backgroundColor={"rgba(0, 0, 0, 0.5)"}
      position="absolute"
      style={{top: 0, left: 0, cursor: 'default'}}
      onClick={onClose}
    >
      <Flex
        backgroundColor="white"
        direction="column"
        justifyContent="center"
        padding="32px"
        onClick={(e) => e.stopPropagation()}
      >
        <Heading color="black">{title}</Heading>
        {children}
      </Flex>
    </Flex>
  ) : <></>;
};

export default Modal;