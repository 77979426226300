import { z } from 'zod';

import { PlaidItemInstitution, PlaidItemStatus } from '../types/enums.js';

export const PlaidItemClientKeySchema = z.object({
  idItem: z.string(),
});

export const PlaidItemClientSchema = PlaidItemClientKeySchema.merge(
  z.object({
    idInstitution: z.nativeEnum(PlaidItemInstitution),
    name: z.string(),
    accounts: z.array(z.string()).optional(),
    status: z
      .object({
        login: z.nativeEnum(PlaidItemStatus).optional(),
        transactions: z.nativeEnum(PlaidItemStatus).optional(),
      })
      .optional(),
    warnings: z.array(z.string()).optional(),
  }),
);
