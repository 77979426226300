import { Button } from "@aws-amplify/ui-react";
import { FC, useState } from "react";

import { type IConnectionInitiateResult } from "@monorepo/core";

import Modal from "../core/modal.component";
import { PlaidLink } from "./plaid-link.component";
import { useCreateConnection, useInitiateConnection } from "../../hooks/connections.hook";

interface ContainerProps {
  showConsentModal: boolean;
  idItem?: string;
  onClose: () => void;
}

const PlaidLinkModal: FC<ContainerProps> = ({ showConsentModal, idItem, onClose }) => {
  const { createConnection } = useCreateConnection();
  const { initiateConnection, isLoading: isInitiatingPlaidConnection } = useInitiateConnection();

  const [initiateConnectionResult, setInitiateConnectionResult] = useState<IConnectionInitiateResult>();

  return (
    <>
    <Modal
        active={showConsentModal}
        title="Consent Required"
        onClose={() => {
          onClose();
        }}
      >
        <div>
            Before connecting your financial accounts, please review and agree to the following:
            <ol type="1">
              <li>By proceeding, you consent to My Cash Flow Report using Plaid to securely connect your bank, investment, and loan accounts.</li>
              <li>Your personal and financial information will be collected, used, and shared as described in our <a href="https://mycashflow.report/privacy-policy.html" target="_blank">Privacy Policy</a> and Plaid’s <a href="https://plaid.com/legal/#end-user-privacy-policy" target="_blank">Privacy Policy</a>.</li>
              <li>The data collected will be used to provide cash flow analysis, net worth tracking, and other financial insights as outlined in our <a href="https://mycashflow.report/privacy-policy.html" target="_blank">Privacy Policy</a>.</li>
              <li>We and Plaid implement industry-standard security measures to protect your data.</li>
              <li>By clicking “I Agree”, you authorize Plaid to access and retrieve your financial information.</li>
            </ol> 
        </div>
        <Button
          isLoading={isInitiatingPlaidConnection}
          onClick={async () => {
            onClose();
            const result = await initiateConnection('plaid-item', idItem);
            setInitiateConnectionResult(result);
          }}
        >
          I Agree
        </Button>
      </Modal>
      <PlaidLink
        linkToken={initiateConnectionResult?.token}
        onSuccess={async (publicToken) => {
          // We don't need to do anything for updates
          if (!idItem) {
            await createConnection('plaid-item', publicToken);
          }
        }}
      />
    </>
  )
};

export default PlaidLinkModal;